import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import { PageLayout } from "../layouts";
import { Block, CheckBox, RadioButton, TextField, TextArea } from "../components";

import { TIME_ZONE, DAYS, AM_PM, NEW_WORLD_CLASSES, NEW_WORLD_PLAY_TYPE, TWO_CHOICES, THREE_CHOICES } from "../global/js/helpers";

/**
 * Image assests for Title Block
 */
import BattleBackground from "../images/War_3_1.svg";

import "../global/styles/page-styles/form-page.scss";
import "../global/styles/page-styles/newworld.scss";

const API_ENDPOINT_NEW_WORLD_SIGN_UP = process.env.API_ENDPOINT_NEW_WORLD_SIGN_UP;

const discordReg = /^.{3,32}#[0-9]{4}$/

const validationSchema = Yup.object().shape({
    email: Yup.string().required('Required').email('Please enter a valid email address'),
    custom_fields: Yup.object().shape({
        w12_T: Yup.string().required('Required'),
        w13_T: Yup.string().required('Required').matches(discordReg, 'Discord name is not valid'),
        w14_T: Yup.string().required('Required'),
        w15_T: Yup.string().required('Required'),
        w16_T: Yup.array().required('Required'),
        w17_T: Yup.string().required('Required'),
        w18_T: Yup.array().required('Required'),
        w19_T: Yup.string().required('Required'),
        w20_T: Yup.string().required('Required'),
        w22_T: Yup.string().required('Required'),
        w24_T: Yup.string().required("Required"),
    }),
});

const NewWorldPage = () => {
    const [formError, setFormError] = useState(undefined);
    const [successData, setSuccessData] = useState(undefined);

    /**
     * Store initial form state.
     * w12_T = character_name
     * w13_T = discord_name
     * w14_T = referral
     * w15_T = time_zone
     * w16_T = days
     * w17_T = time_of_day
     * w18_T = class
     * w19_T = gold
     * w20_T = experience
     * w21_T = gameplay
     * w22_T = about
     * w23_T = leadership
     * w24_T = ideals
     */
    const initialValues = {
        email: '',
        custom_fields: {
            w12_T: '',
            w13_T: '',
            w14_T: '',
            w15_T: '',
            w16_T: '',
            w17_T: '',
            w18_T: '',
            w19_T: '',
            w20_T: '',
            w21_T: '',
            w22_T: '',
            w23_T: '',
            w24_T: '',
        },
    }

    const handleSubmit = (values) => {
        axios.post(API_ENDPOINT_NEW_WORLD_SIGN_UP, values)
            .then((res) => {
                if (res.data === 'Accepted') {
                    // Update UI to show Success MSG.
                    setSuccessData(values);
                }
                else {
                    setFormError(`There was an error. Please try again later.`)
                }
            })
            .catch(error => {
                // Update UI to show Error MSG.
                console.log('submit error: ', error);
                setFormError(`${error}. Please try again later.`)
            });
    };

    return (
        <>
            <Helmet>
                <title>Partie | New World Application</title>
                <html lang="en" />
                <meta name="description" content="The Partie Company on US: West - Camelot is actively recruiting competitive, active and dedicated players to fill our ranks." />
            </Helmet>
            <PageLayout className="form-page">
                <Block centered hero lazy>
                    <h2 className="partie-block__title">Join the Partie Company!</h2>
                    <p className="partie-block__description">
                        The Partie Company on <strong>US: West - Camelot</strong> is actively recruiting <br className="hidden" /> competitive, active and dedicated players to fill our ranks.
                    </p>
                    <p className="partie-block__description">
                        Partie is an inclusive, friendly company that prides itself on <br className="hidden" /> teamwork and creating positive gameplay experiences <br className="hidden" /> in a non-toxic environment where all are welcome.
                    </p>
                    <p className="partie-block__description">
                        Sound like you? Apply below.  <br className="hidden" />
                    </p>
                    <img src={BattleBackground} className="battle-background" alt="battlefield" aria-hidden />
                </Block>
                <Block form>
                    {successData ? (
                        <div className="success-message">
                            <p>Thank you,
                                your application has been
                                submitted for review.</p><p>
                                We will contact you
                                for additional information
                                based on your eligibility.</p>
                            <a className="btn btn--accent" href="https://app.partie.com">
                                To Partie
                            </a>
                        </div>
                    ) : (
                        <div>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={handleSubmit}
                                validationSchema={validationSchema}
                            >
                                <Form className="partie-form" id="partie-form">
                                    <TextField name="custom_fields.w12_T" label="Character Name" required={true} type="text" />
                                    <TextField name="email" label="Email" required={true} type="email" />
                                    <TextField name="custom_fields.w13_T" label="Discord Name (ex: Partietime#8425)" required={true} type="text" />
                                    <TextArea name="custom_fields.w14_T" label="How did you find out about us? (Referral)" required={true} />
                                    <RadioButton name="custom_fields.w15_T" label="Time zone" required={true} options={TIME_ZONE} />
                                    <CheckBox name="custom_fields.w16_T" label="Availability" required={true} options={DAYS} />
                                    <RadioButton name="custom_fields.w17_T" label="Time of Day you typically play" required={true} options={AM_PM} />
                                    <CheckBox name="custom_fields.w18_T" label="Preferred Class (can choose more than one)" required={true} options={NEW_WORLD_CLASSES} />
                                    <RadioButton name="custom_fields.w19_T" label="Are you willing to contribute gold to the company to help with territory purchases?" required={true} options={TWO_CHOICES} />
                                    <CheckBox name="custom_fields.w21_T" label="What type of gameplay are you most intersted in?" options={NEW_WORLD_PLAY_TYPE} />
                                    <TextArea name="custom_fields.w20_T" label="Previous MMO experience" required={true} />
                                    <TextArea name="custom_fields.w22_T" label="Tell us about yourself, and what you can contribute to the group." required={true} />
                                    <RadioButton name="custom_fields.w23_T" label="Are you interested in a leadership position within the company?" options={THREE_CHOICES} />
                                    <TextArea name="custom_fields.w24_T" label="What are you looking for from Partie Company Leadership" required={true} />
                                    <div className="partie-form__field-container">
                                        <button className="btn btn--accent" type="submit">
                                            Submit
                                        </button>
                                        {formError && (
                                            <div className="partie-form__field-caption partie-form__submit-error">{formError}</div>
                                        )}
                                    </div>
                                </Form>
                            </Formik>
                        </div>
                    )}
                </Block>
            </PageLayout>
        </>
    );
};

export default NewWorldPage;